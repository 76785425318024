import React, { useState, useContext} from "react";

import {
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Link
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import { system } from "../../config.json";
import logo from '../../assets/logo.png';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ color: '#ffffff' }}>
      © {new Date().getFullYear()}
      {" - "}
      <Link color="inherit" href={system.url || "https://www.novaforma3d.com.br"} style={{ color: '#ffffff' }}>
        {system.name}
      </Link>
      {"."}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#000', // Cor de fundo preta
      minHeight: '100vh', // Garante que o fundo cubra toda a tela
      overflow: 'auto', // Bloqueia a rolagem dentro do componente
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start', // Centraliza verticalmente
      alignItems: 'center',
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2), // Adiciona padding para garantir espaço interno
      },
      logo: {
        width: '50vw',  // 60% da largura da viewport
        maxWidth: '871px',  // Limite máximo de 871px
        minWidth: '292px',  // Limite mínimo de 292px
        height: 'auto',  // Mantém a proporção
        marginTop: '10%',
        marginBottom: theme.spacing(3), // Espaço abaixo da logo
      },
      form: {
        width: "90%", // Fix IE 11 issue.
        marginTop: '0px',
        color: '#ffffff', // Cor do texto dentro dos campos
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#e8f1f0', // Cor de fundo do botão
        color: '#000', // Cor do texto do botão
      },
      textField: {
        '& label': {
          color: '#ffffff', // Cor dos rótulos (labels) em estado normal
        },
        '& label.Mui-focused': {
          color: '#ffffff', // Cor dos rótulos (labels) quando o campo está focado
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#ffffff', // Cor da borda inferior quando focada
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#ffffff', // Cor da borda do campo
          },
          '&:hover fieldset': {
            borderColor: '#e8f1f0', // Cor da borda ao passar o mouse
          },
          '&.Mui-focused fieldset': {
            borderColor: '#e8f1f0', // Cor da borda quando focada
          },
        },
        '& input': {
          color: '#ffffff', // Cor do texto dentro do campo
        },
        '& input:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px #000 inset', // Muda o fundo do auto-completar para preto
          WebkitTextFillColor: '#ffffff', // Muda a cor do texto para branco
        },
      },
      link: {
        color: '#e8f1f0', // Cor dos links
      },
    }));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img alt="logo" src={logo} className={classes.logo} />
          <Typography component="h1" variant="h5" style={{ color: '#ffffff' }}>
            {i18n.t("login.title")}
          </Typography>
          <form className={classes.form} noValidate onSubmit={handlSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={i18n.t("login.form.email")}
              name="email"
              value={user.email}
              onChange={handleChangeInput}
              autoComplete="email"
              autoFocus
              className={classes.textField}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={i18n.t("login.form.password")}
              id="password"
              value={user.password}
              onChange={handleChangeInput}
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              className={classes.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((e) => !e)}
                    >
                      {showPassword ? <VisibilityOff style={{ color: '#ffffff' }} /> : <Visibility style={{ color: '#ffffff' }} />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              {i18n.t("login.buttons.submit")}
            </Button>
            <Copyright />
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Login;